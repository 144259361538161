@import 'Styles/includes';

.Header {
    $root: &;

    position: relative;

    &--MegaMenu {
        z-index: $z-Header--Mega;

        &::before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: black;
            opacity: .3;
        }
    }

    &__Wrap {
        @include wrap;

        position: relative;
        display: flex;
        align-items: center;
    }

    &__Top {
        position: relative;
        background: $red-5;
    }

    &__Main {
        position: relative;
        background: white;
        border-bottom: 1px solid $grey-20;

        #{$root}--HasCTA & {
            padding-bottom: 50px;

            @include media(M) {
                padding-bottom: 56px;
            }

            @include media(ML) {
                padding-bottom: 0;
            }
        }
    }

    &__Mega {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding-top: 40px;
        padding-bottom: 64px;
        border-bottom: 1px solid $grey-20;
        background: white;
        z-index: $z-Header__Mega;

        @include media(ML) {
            display: block;
        }

        @include media(XL) {
            padding-top: 64px;
            padding-bottom: 80px;
        }
    }

    &__Logo {
        flex: 0 0 auto;
        display: block;
        padding: 11px 16px;
        margin: 0 8px 0 -16px;

        @include media(ML) {
            padding: 16px 16px;
        }

        @include media(L) {
            padding: 24px 24px;
            margin: 0 24px 0 -24px;
        }

        #{$root}--Intranet & {
            padding: 20px 16px;

            @include media(ML) {
                padding: 24px 16px;
            }

            @include media(L) {
                padding: 32px 24px;
            }
        }

        img {
            display: block;
            width: auto;
            height: 42px;

            @include media(ML) {
                height: 56px;
            }

            @include media(L) {
                height: 64px;
            }

            #{$root}--Intranet & {
                height: 24px;

                @include media(ML) {
                    height: 40px;
                }

                @include media(L) {
                    height: 48px;
                }
            }
        }

        &--Small {
            padding: 10px 16px !important;
            margin: -5px 8px -5px -16px;

            @include media(L) {
                margin-right: 32px;
            }

            img {
                height: 20px !important;

                @include media(ML) {
                    height: 32px !important;
                }

                @include media(L) {
                    height: 32px !important;
                }
            }
        }
    }

    &__Local {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0 6px;
        margin-right: 24px;

        @include media(ML) {
            padding: 16px 0 11px;
        }

        @include media(L) {
            padding: 25px 0;
            margin-right: 48px;
            min-height: 112px;
        }
    }

    &__LocalCounty {
        color: $grey-70;
        font-size: 1.2rem;

        @include media(ML) {
            font-size: 1.4rem;
        }
    }

    &__LocalName {
        color: $red;
        font-size: 3rem;
        font-weight: $bold;
        line-height: 1.2;

        @include media(ML) {
            font-size: 4rem;
        }
    }

    &__Nav {
        flex: 0 0 auto;

        &--Top {
            align-self: flex-start;
            margin-left: -8px; // Same as last item right padding

            @include media(ML) {
                margin-left: -16px; // Same as last item left padding
            }
        }

        &--RightTop {
            align-self: flex-start;
            justify-self: flex-end;
            margin-left: auto;
            margin-right: -8px; // Same as last item left padding

            @include media(ML) {
                margin-right: -16px; // Same as last item left padding
            }
        }

        &--CTA {
            @include media-max(ML) {
                @include wrap;

                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                padding-bottom: 8px;
            }

            @include media(ML) {
                margin-left: 8px;
            }

            @include media(L) {
                margin-left: 16px;
            }
        }

        &--Mega {
            width: 100%;
            padding: 44px 0 80px;
        }

        &--Main {
            align-self: flex-end;
        }

        &--RightMain {
            justify-self: flex-end;
            margin-left: auto;
        }

        @include media-max(ML) {
            &--Top,
            &--Main {
                display: none;
            }
        }
    }

    &__RightMain {
        display: flex;
    }

    &__Item,
    :global(.woocommerce-cart-link) {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        font-size: 1.3rem;
        line-height: 1.85;
        border: 2px solid $red-5;
        border-radius: 50%;
        transition: background $transition;

        @include media-max(L) {
            padding: 0;
        }

        @include media(ML) {
            width: 48px;
            height: 48px;
        }

        &:not(:first-child) {
            margin-left: 8px;

            @include media(L) {
                margin-left: 16px;
            }
        }

        &:hover {
            background: $red-5;

            svg * {
                fill: $red;
            }
        }

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 8px;
            margin: auto;
            width: 20px;
            height: 20px;

            @include media(ML) {
                left: 11px;
                width: 22px;
                height: 22px;
            }

            * {
                fill: $grey-90;
                transition: fill $transition;
            }
        }
    }

    :global {
        .woocommerce-cart-link {
            svg {
                @include media(ML) {
                    width: 24px;
                    height: 24px;
                }
            }

            .cart-count {
                position: absolute;
                top: -2px;
                left: 28px;
                min-width: 20px;
                height: 20px;
                padding: 3px 6px;
                color: white;
                font-size: 1rem;
                font-weight: $bold;
                line-height: normal;
                text-align: center;
                border-radius: 10px;
                background: $red;
            }
        }
    }

    &__Toggle {
        margin-left: 8px;
        margin-right: -8px;
        padding: 8px;
        border: none;
        border-radius: 0;
        background: transparent;
        -webkit-appearance: none;
        cursor: pointer;

        @include media(ML) {
            display: none;
        }

        svg {
            display: block;
            width: 24px;
            height: 24px;

            * {
                fill: $grey-90;
                transition: fill $transition;
            }
        }

        &:hover {
            svg * {
                fill: $red;
            }
        }
    }

    &__MobileMenu {
        display: none;
        flex-direction: column;
        position: relative;
        border-top: 1px solid $grey-20;
        background: white;
        z-index: $z-Header__MobileMenu;

        #{$root}--HasCTA & {
            top: -51px;

            @include media(M) {
                top: -57px;
            }

            @include media(ML) {
                top: 0;
            }
        }

        @include media(ML) {
            display: none !important;
        }

        #{$root}--MobileMenu & {
            display: flex;
        }
    }

    &__MobileWrap {
        @include wrap;

        width: 100%;
        padding-top: 16px;
        padding-bottom: 62px;
    }

    &__MobileNav {
        &--CTA {
            margin-bottom: 20px;
        }

        &--Top {
            margin-top: 32px;
        }
    }
}

@import 'Styles/includes';

.Image {
    $root: &;

    width: 100%;
    height: 100%;

    &--Contain {
        object-fit: contain;
        background-size: contain;
    }

    &--Cover#{&}--ObjectFit {
        object-fit: cover;
    }

    &--Cover#{&}--Fallback {
        background-size: cover;
    }

    // Woocommerce adds styling to img-tag, force this styling instead
    :global {
        .woocommerce &,
        .woocommerce-page & {
            width: 100%;
            height: 100%;
        }
    }
}

@import 'Styles/includes';

.Snippet {
    $root: &;

    margin-top: 24px;
    margin-bottom: 24px;

    @include media(M) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &--Gravityforms {
        margin: 0 !important;
    }

    &--HorsellinjenAudio {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
        }

        li {
            flex: 0 0 auto;
            width: calc(50% - 10px);
            max-width: 200px;
            margin-left: 10px;
            margin-bottom: 10px;
        }

        button {
            position: relative;
            display: block;
            min-height: 40px;
            width: 100%;
            padding: 10px 12px 10px 36px;
            border: none;
            cursor: pointer;
            font-weight: $bold;
            border-radius: 4px;
            background: $link-color;
            color: white;
            background-position: 11px 50%;
            transition: background-color $transition;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 10px;
                width: 16px;
                height: 16px;
                margin: auto;
                background: url('#{$assetsPath}/img/play--white.svg') no-repeat center center;
                background-size: contain;
            }

            &:hover {
                background-color: $link-hover-color;
            }

            &:focus {
                // outline: @color--green auto 5px;
            }

            &.js-state-hl-playing {
                &::before {
                    background-image: url('#{$assetsPath}/img/pause--white.svg');
                }
            }
        }
    }
}

@import 'Styles/includes';

.Footer {
    $root: &;

    background: $grey-10;

    &__Container {
        @include wrap;

        padding-top: 24px;

        @include media(M) {
            padding-top: 40px;
            padding-bottom: 48px;
        }

        @include media(ML) {
            padding-top: 60px;
            padding-bottom: 68px;
        }
    }

    &__Content {
        position: relative;
    }

    &__Logo {
        position: absolute;
        top: 20px;
        left: 0;
        width: 109px;
        height: 40px;
        margin-bottom: 24px;

        @include media(M) {
            width: 153px;
            height: 56px;
            margin-bottom: 40px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__Nav {
        &--Footer:not(:first-of-type) {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid $grey-30;

            @include media(M) {
                margin-top: 40px;
                padding-top: 32px;
            }

            @include media(ML) {
                margin-top: 64px;
                padding-top: 44px;
            }
        }

        &--SocialMedia {
            margin-bottom: 16px;

            @include media(M) {
                margin-bottom: 0;
            }
        }

        &--Service {
            margin-bottom: 32px;

            @include media(M) {
                margin-bottom: 0;
            }
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;

        &--Children {
            flex-direction: column;
        }

        #{$root}__Nav--Footer & {
            margin-left: -16px;

            @include media(ML) {
                margin-left: -24px;
            }

            @include media(L) {
                margin-left: -38px;
            }
        }

        #{$root}__Nav--SocialMedia & {
            justify-content: center;
            margin-left: -20px;

            @include media(M) {
                justify-content: flex-start;
            }
        }

        #{$root}__Nav--Service & {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include media(ML) {
                flex-direction: row;
            }
        }
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        #{$root}__Nav--Footer & {
            margin-left: 16px;
            width: calc(100% - 16px);

            @include media(M) {
                width: calc(50% - 16px);
            }

            @include media(ML) {
                margin-left: 24px;
                width: calc(25% - 24px);
            }

            @include media(L) {
                margin-left: 38px;
                width: calc(25% - 38px);
            }
        }

        #{$root}__Nav--Footer:first-of-type #{$root}__List:not(#{$root}__List--Children) &:first-child {
            margin-top: 76px;

            @include media(M) {
                margin-top: 96px;
            }
        }

        #{$root}__Nav--Footer > #{$root}__List > & {
            margin-bottom: 16px;

            @include media(M) {
                margin-bottom: 0;
            }
        }

        #{$root}__Nav--Footer #{$root}__List--Children & {
            width: auto;
            margin-top: 0 !important;
        }

        #{$root}__Nav--Service & {
            margin: 0 2px;
        }
    }

    &__Text {
        margin-top: 12px;
        max-width: 290px;
        font-size: 1.4rem;
        line-height: 1.52;

        p {
            margin: 12px 0 0;
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 1.52;
        }

        a {
            color: $grey-90;
            font-size: 1.4rem;
            transition: color $transition;

            &:hover {
                color: $red;
                text-decoration: underline;
            }

            &::after {
                content: '';
                display: none;
                position: relative;
                top: 3px;
                width: 17px;
                height: 17px;
                margin-left: 8px;
                background: url('#{$assetsPath}/img/external--grey-90.svg') no-repeat center center;
                background-size: contain;
                transition: background $transition;
            }

            @include href-external {
                &::after {
                    display: inline-block;
                }

                &:hover {
                    &::after {
                        background-image: url('#{$assetsPath}/img/external--red.svg');
                    }
                }
            }
        }

        b,
        strong {
            font-weight: $bold;
        }

        #{$root}__Link--Copy & {
            margin: 0;
            font-size: 2.4rem;
            font-weight: $bold;
            line-height: 1.5;
        }
    }

    &__Link {
        position: relative;
        padding: 8px 0;
        color: $grey-90;
        font-size: 1.4rem;
        line-height: 1.5;
        transition: color $transition;

        @include media(M) {
            padding: 12px 0;
            font-size: 1.6rem;
        }

        &:not(#{$root}__Link--NoLink):hover {
            color: $red;
        }

        &--Hide {
            display: none;
        }

        &--Copy {
            padding: 10px 16px 8px;
            margin-top: 16px;
            color: $grey-90;
            text-align: left;
            border: none;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            transition: color $transition, background $transition;

            &:hover {
                color: white;
                background: $red;
            }
        }

        #{$root}__Nav--SocialMedia & {
            flex: 0 0 auto;
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            padding: 0;
            margin-left: 20px;
            border-radius: 50%;
            background: $grey-90;
            transition: background $transition;

            &:not(#{$root}__Link--NoLink):hover {
                background: $red;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 20px;
                height: 20px;
                margin: auto;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }

            &--Facebook::after {
                top: -1px;
                left: -1px;
                width: 19px;
                height: 19px;
                background-image: url('#{$assetsPath}/img/facebook--white.svg') !important;
            }

            &--Twitter::after {
                background-image: url('#{$assetsPath}/img/twitter--white.svg') !important;
            }

            &--Instagram::after {
                background-image: url('#{$assetsPath}/img/instagram--white.svg') !important;
            }

            &--Linkedin::after {
                top: -1px;
                width: 21px;
                height: 21px;
                background-image: url('#{$assetsPath}/img/linkedin--white.svg') !important;
            }
        }

        #{$root}__Nav--Service & {
            padding: 12px 16px;
            font-size: 1.4rem;

            @include media(ML) {
                padding: 9px 16px;
            }
        }
    }

    &__LinkText {
        &::after {
            content: '';
            display: block;
            height: 1px;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            background: $red;
            opacity: 0;
            transition: opacity $transition;
        }

        &:global(:not(.sr-only)) {
            position: relative;
        }

        #{$root}__Link:not(#{$root}__Link--NoLink):hover & {
            &::after {
                opacity: 1;
            }
        }

        #{$root}__Link--NoLink > & {
            position: relative;
            margin: 8px 0 0;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1.33;
            text-transform: uppercase;

            &::after {
                display: none;
            }
        }

        #{$root}__Link--Copy & {
            margin: 0;
            font-size: 1.2rem;
            font-weight: $regular;
            text-transform: none;
            line-height: 1.52;
        }
    }

    &__Link {
        @include href-external {
            #{$root}__LinkText {
                margin-right: 25px;
            }

            #{$root}__External {
                display: block;
            }
        }
    }

    &__Copy {
        display: block;
        position: absolute;
        top: 50%;
        right: 16px;
        margin: auto;
        color: $grey-60;
        font-size: 1.2rem;
        font-weight: $bold;
        text-transform: uppercase;
        line-height: normal;
        transform: translateY(-50%);
        transition: color $transition;

        svg {
            position: relative;
            top: 3px;
            width: 16px;
            height: 16px;
            margin-left: 9px;
            fill: $grey-60;
            transition: fill $transition;
        }

        #{$root}__Link:hover & {
            color: white;

            svg {
                fill: white;
            }
        }
    }

    &__External {
        display: none;
        position: absolute;
        bottom: 2px;
        right: -25px;
        width: 14px;
        height: 14px;

        svg {
            display: block;
            width: 14px;
            height: 14px;
            fill: $grey-90;
            transition: fill .3s ease-in-out;

            #{$root}__Link:not(#{$root}__Link--NoLink):hover & {
                fill: $red;
            }
        }
    }

    &__Image {
        display: none;
    }

    &__Footer {
        @include media(M) {
            background: white;
        }
    }

    &__Wrap {
        @include wrap;

        padding-top: 16px;
        padding-bottom: 48px;

        @include media(M) {
            padding-top: 44px;
            padding-bottom: 44px;
        }
    }

    &__Columns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -16px;

        @include media(ML) {
            margin-left: -24px;
        }

        @include media(L) {
            margin-left: -38px;
        }
    }

    &__Column {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        width: calc(100% - 16px);

        @include media(M) {
            width: calc(#{percentage(1/3)} - 16px);
        }

        @include media(ML) {
            margin-left: 24px;
            width: calc(#{percentage(1/3)} - 24px);
        }

        @include media(L) {
            margin-left: 38px;
            width: calc(#{percentage(1/3)} - 38px);
        }
    }

    &__Logos {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -32px;

        @include media(M) {
            justify-content: flex-end;
            margin-left: -40px;
        }

        img {
            width: auto;
            height: 32px;
            margin-left: 32px;

            @include media(M) {
                height: 40px;
                margin-left: 40px;
            }
        }
    }
}

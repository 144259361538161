@import 'Styles/includes';

.Button {
    $root: &;

    &--Full {
        width: 100%;
    }

    &--Primary {
        @extend %button--primary;

        &#{$root}--Disabled {
            @extend %button--primary-disabled;
        }
    }

    &--PrimaryLink {
        @extend %button--primary-link;
    }

    &--Secondary {
        @extend %button--secondary;

        &#{$root}--Disabled {
            @extend %button--secondary-disabled;
        }
    }

    &--SecondaryLink {
        @extend %button--secondary-link;
    }

    &--Tertiary {
        @extend %button--tertiary;
    }

    &--TertiaryLink {
        @extend %button--tertiary-link;
    }

    &--Blue {
        @extend %button--blue;

        &#{$root}--Disabled {
            @extend %button--blue-disabled;
        }
    }

    &--Back {
        @extend %button--back;
    }
}

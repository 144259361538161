@import 'Styles/includes';

.Breadcrumbs {
    $root: &;

    border-bottom: 1px solid $grey-20;
    background: $grey-5;

    &__Nav {
        @include wrap;
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;
        padding-top: 3px;
        padding-bottom: 3px;

        @include media(M) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__Item {
        flex: 0 0 auto;
        position: relative;

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -2px;
            margin: auto;
            width: 6px;
            height: 8px;
            background: url('#{$assetsPath}/img/breadcrumb--grey-70.svg') no-repeat center center;
            background-size: contain;

            @include media(M) {
                right: 0;
            }
        }

        &:not(:last-child) {
            padding-right: 4px;

            &::after {
                display: block;
            }

            @include media(M) {
                margin-right: 3px;
                padding-right: 8px;
            }
        }
    }

    &__Link {
        display: inline-block;
        position: relative;
        padding: 4px 8px;
        color: $base-color;
        font-size: 1.2rem;
        text-decoration: none;
        line-height: 1.52;
        transition: color $transition;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 4px;
            left: 8px;
            right: 8px;
            height: 1px;
            background: $red;
            opacity: 0;
            transition: opacity $transition;
        }

        &--Current {
            color: $grey-70;
        }

        &:not(#{$root}__Link--Current):hover {
            color: $red;

            &::after {
                opacity: 1;
            }
        }

        @include media(M) {
            font-size: 1.4rem;
        }
    }
}

@import 'Styles/includes';

.Nav {
    $root: &;

    &--Mega {
        width: 100%;
    }

    &__Children {

    }

    &__List {
        display: flex;
        flex-direction: column;

        @include media(ML) {
            flex-direction: row;
        }

        &--Hide {
            display: none;
        }

        #{$root}--Main &--Children {
            @include media(ML) {
                display: none !important;
            }
        }

        #{$root}--CTA & {
            flex-direction: row;
            margin-left: -8px;
        }

        #{$root}--RightTop &:not(#{$root}__List--Children) {
            flex-direction: row;
        }

        #{$root}--Mega &:not(#{$root}__List--Children) {
            flex-wrap: wrap;
            margin-left: -38px;
            margin-bottom: -38px;
        }

        #{$root}--Mega &--Children {
            flex-direction: column;
            border-top: 1px solid $grey-20;

            @include media(ML) {
                flex-direction: column;
            }
        }

        #{$root}--Mega #{$root}__List--Children &--Children {
            border: none;
        }

        #{$root}--Mobile > #{$root}__List > #{$root}__Item > &,
        #{$root}--Mobile > #{$root}__List > #{$root}__Item > #{$root}__List--Children > #{$root}__Item > &,
        #{$root}--Mobile > #{$root}__List > #{$root}__Item > #{$root}__Children > &,
        #{$root}--Mobile > #{$root}__List > #{$root}__Item > #{$root}__Children > #{$root}__List--Children > #{$root}__Item > #{$root}__Children > & {
            margin-bottom: 16px;
        }

        #{$root}--RightTop &--Children {
            position: absolute;
            top: 100%;
            left: 20px;
            min-width: 100%;
            padding: 8px 0;
            background: $red-5;
            z-index: 1;

            @include media(ML) {
                flex-direction: column;
            }
        }
    }

    &__Item {
        position: relative;

        #{$root}--CTA & {
            flex: 0 0 auto;
            width: calc(50% - 8px);
            margin-left: 8px;

            @include media(ML) {
                width: auto;
                margin-top: 0;
                margin-left: 8px;
            }
        }

        #{$root}--CTA &:not(:first-child) {
            // margin-top: 12px;
            flex: 0 0 auto;
            width: calc(50% - 8px);
            margin-left: 8px;

            @include media(ML) {
                width: auto;
                margin-top: 0;
                margin-left: 8px;
            }
        }

        #{$root}--Mega #{$root}__List:not(#{$root}__List--Children) > & {
            flex: 0 0 auto;
            width: calc(25% - 38px);
            margin-left: 38px;
            margin-bottom: 38px;
        }

        #{$root}--Mobile #{$root}__List:not(#{$root}__List--Children) > & {
            border-bottom: 1px solid $grey-20;
        }

        #{$root}--Mega #{$root}__List--Children & {
            border-bottom: 1px solid $grey-20;
        }

        #{$root}--Mega #{$root}__List--Children #{$root}__List--Children & {
            border: none;
            margin-left: 16px;
        }
    }

    &__Toggle {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 50px;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: white;
        cursor: pointer;

        svg {
            width: 8px;
            height: 12px;
            transform: rotate(90deg);
            transition: transform .3s ease-in-out;

            #{$root}__Item--Expanded > & {
                transform: rotate(-90deg);
            }
        }

        #{$root}--Mobile #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > & {
            height: 56px;
        }

        #{$root}--RightTop & {
            width: 100%;
            height: 100%;
            background: transparent;

            svg {
                display: none;
            }
        }
    }

    &__Link {
        flex: 0 0 auto;
        position: relative;
        display: block;
        padding: 20px 16px;
        font-size: 1.6rem;
        line-height: 1.5;
        transition: color $transition, background $transition;

        &:not(#{$root}__Link--NoLink):hover,
        &--Current {
            color: $red;

            svg * {
                fill: $red;
            }

            &::after {
                opacity: 1 !important;
            }
        }

        #{$root}--Main &--Parent {
            &::after {
                opacity: 1 !important;
            }
        }

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 24px;
            height: 24px;
            margin: auto;

            * {
                fill: $grey-90;
                transition: fill $transition;
            }
        }

        &--Large {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 220px;
            margin-top: 20px;
            padding: 32px 24px !important;
            font-size: 1.8rem !important;
            text-align: center;
            border-radius: 4px;
            background: $red-5;

            &::after {
                display: block !important;
                top: auto !important;
                margin: 20px 0 0 !important;
                background-color: $red !important;
                background-image: url('#{$assetsPath}/img/arrow-large--white.svg') !important;
            }

            &#{$root}__Link--HasImage {
                min-height: 0 !important;
                padding: 0 !important;
                justify-content: flex-start;
                align-items: flex-start;

                &::after {
                    position: absolute !important;
                    bottom: 16px;
                    right: 24px;
                }
            }

            #{$root}--Mobile & {
                margin: 24px 8px;
            }
        }

        // Only first level
        #{$root}--Main > #{$root}__List > #{$root}__Item > & {
            padding-top: 8px;
            padding-bottom: 20px;
            font-weight: $medium;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            @include media(ML) {
                padding-bottom: 32px;
            }

            @include media(L) {
                padding-bottom: 43px;
            }

            &--Current,
            &--Parent {
                background: $red-5;
            }

            &:hover {
                &::after {
                    background: $red !important;
                }
            }

            &::after {
                content: '';
                display: block;
                height: 5px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: $red;
                background-image: none !important; // Fix bug if external link is added to main menu
                opacity: 0;
                transition: opacity $transition, background $transition;
            }
        }

        #{$root}--Main > #{$root}__List > #{$root}__Item--Expanded > & {
            &::after {
                opacity: 1 !important;
                background: $red-20 !important;
            }
        }

        #{$root}--Mobile > #{$root}__List > #{$root}__Item > & {
            padding-top: 15px;
            padding-bottom: 14px;
        }

        #{$root}--Top &,
        #{$root}--RightTop & {
            padding: 8px 8px 9px;
            line-height: normal;

            @include media(ML) {
                padding: 12px 16px;
            }

            &--OnlyIcon {
                min-height: 34.5px;
                padding-left: 8px + 13px !important;

                @include media(ML) {
                    min-height: 40px;
                    padding-left: 16px + 13px !important;
                }
            }
        }

        #{$root}--Top & {
            font-size: 1.4rem;

            @include media(ML) {
                font-size: 1.3rem;
            }

            > svg {
                top: 9px;
                bottom: auto;
                left: 8px;
                width: 13px;
                height: 13px;

                @include media(ML) {
                    top: 12px;
                    left: 15px;
                    width: 16px;
                    height: 16px;
                }
            }

            &--HasIcon {
                padding-left: 28px;

                @include media(ML) {
                    padding-left: 36px;
                }
            }
        }

        #{$root}--RightTop & {
            font-size: 1.2rem;

            @include media(ML) {
                font-size: 1.3rem;
            }

            > svg {
                top: 9px;
                bottom: auto;
                left: 16px;
                width: 13px;
                height: 13px;

                @include media(ML) {
                    top: 12px;
                    width: 16px;
                    height: 16px;
                }
            }

            &--HasIcon {
                padding-left: 36px;
            }
        }

        #{$root}--CTA & {
            display: block;
            width: 100%;
            text-align: center;
        }

        #{$root}--CTA #{$root}__Item:not(:first-child) & {
            @extend %button--secondary;
            @extend %button--smallM;
        }

        #{$root}--CTA #{$root}__Item:first-child & {
            @extend %button--primary;
            @extend %button--smallM;
        }

        #{$root}--Mega #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > &,
        #{$root}--Mobile #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > & {
            white-space: nowrap;
            font-size: 1.8rem;
            font-weight: $bold;
        }


        #{$root}--Mega #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > & {
            padding-left: 0;
        }

        #{$root}--Mega #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > &,
        #{$root}--Mobile > #{$root}__List > #{$root}__Item > #{$root}__List--Children > #{$root}__Item > &,
        #{$root}--Mobile > #{$root}__List > #{$root}__Item > #{$root}__Children > #{$root}__List--Children > #{$root}__Item > & {
            font-weight: $bold;

            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: 9px;
                width: 32px;
                height: 32px;
                margin: -9px 0 -3px 12px;
                border-radius: 50%;
                background: $red-5 url('#{$assetsPath}/img/arrow-large--red.svg') no-repeat center center;
                background-size: 16px;
                transition: transform $transition, background $transition;
            }

            &:not(#{$root}__Link--NoLink):not(#{$root}__Link--Large):hover,
            &#{$root}__Link--HasImage:not(#{$root}__Link--NoLink):hover {
                &::after {
                    background-color: $red !important;
                    background-image: url('#{$assetsPath}/img/arrow-large--white.svg');
                    transform: translateX(5px);
                }
            }

            &:not(#{$root}__Link--Large) {
                margin-right: 32px;
            }
        }

        #{$root}--Mega #{$root}__List--Children & {
            padding: 12px 16px;
        }

        #{$root}--Mega #{$root}__List--Children #{$root}__List--Children & {
            padding: 8px 16px;
            font-size: 1.4rem;
        }

        #{$root}--Mobile #{$root}__List--Children & {
            padding: 10px 16px 12px;
        }

        #{$root}--Mobile #{$root}__List--Children #{$root}__List--Children & {
            padding: 8px 16px 8px 32px;

            &::before {
                content: '';
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 5px;
                background: $red;
            }

            &--Current {
                background: $red-5;

                &::before {
                    display: block;
                }
            }
        }

        #{$root}--Mobile #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 48px;
        }

        #{$root}--Mobile #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 72px;
        }

        #{$root}--Mobile #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 88px;
        }

        #{$root}--RightTop #{$root}__List--Children & {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    &__Image {
        display: none;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 53.16%;

        #{$root}__Link--Large#{$root}__Link--HasImage & {
            display: block;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    }

    &__Shell {
        @extend %shell--before;
        @extend %shell--after;

        border-radius: 4px;

        &::before,
        &::after {
            transition: transform $transition;
        }

        #{$root}__Item:nth-child(even) > #{$root}__Link & {
            &::before {
                width: 1000px;
                height: 710px;
                left: -659px;
                top: -503px;
            }

            &::after {
                width: 1000px;
                height: 710px;
                right: -490px;
                top: calc(100% + 75px);
                transform: rotate(-90deg);
            }
        }

        #{$root}__Item:nth-child(odd) > #{$root}__Link & {
            &::before {
                width: 548px;
                height: 388px;
                right: -224px;
                top: 65px;
            }

            &::after {
                display: none;
            }
        }

        #{$root}__Link:hover & {
            &::before {
                transform: scale(1.05);
            }

            &::after {
                transform: rotate(-90deg) scale(1.05);
            }
        }
    }

    &__LinkText {
        display: inline-block;
        position: relative;
        white-space: nowrap;

        > span:first-child {
            position: relative;
            white-space: normal;

            &::after {
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                background: $red;
                opacity: 0;
                transition: opacity $transition;
            }
        }

        #{$root}__Link:not(#{$root}__Link--NoLink):hover &,
        #{$root}__Link--Current & {
            > span::after {
                opacity: 1;
            }
        }

        #{$root}--Main &,
        #{$root}--CTA & {
            > span::after {
                display: none;
            }
        }

        #{$root}--Mobile #{$root}__List--Children #{$root}__List--Children #{$root}__Link--Current:not(:hover) & {
            > span::after {
                opacity: 0;
            }
        }

        #{$root}__Link--Large & {
            display: block;

            > span::after {
                display: none;
            }
        }

        #{$root}__Link--Large#{$root}__Link--HasImage & {
            padding: 16px 64px 20px 24px;
            width: 100%;
            text-align: left;
        }

        #{$root}__Link--MyPages & {
            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -3px;
                margin-left: 8px;
                padding: 2px;
                border: solid black;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                transition: color $transition;

                #{$root}__Item--Expanded & {
                    top: 0px;
                    transform: rotate(-135deg);
                }
            }

            #{$root}__Item:hover &::after {
                border-color: $red;
            }
        }
    }

    &__Description {
        display: none;
        position: relative;
        margin-top: 3px;
        font-size: 1rem;
        font-weight: normal;
        line-height: normal;
        white-space: normal;

        @include media(ML) {
            display: block;
        }

        #{$root}__Link--Large & {
            display: block;
            margin-top: 8px;
            font-size: 1.4rem;
        }

        #{$root}__Link--Large#{$root}__Link--HasImage & {
            display: none !important;
        }

        #{$root}--RightTop #{$root}__Link--LoggedIn & {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 3px;
                left: -13px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $green;
            }
        }
    }

    &__Text {
        white-space: normal;

        #{$root}__Link--Large#{$root}__Link--HasImage & {
            display: none !important;
        }
    }

    &__External {
        display: none;
        position: absolute;
        top: 4px;
        right: -25px;
        width: 14px;
        height: 14px;

        svg {
            display: block;
            width: 14px;
            height: 14px;
            fill: $grey-90;
            transition: fill .3s ease-in-out;
        }
    }

    &__Link {
        @include href-external {
            #{$root}__LinkText {
                margin-right: 25px;
            }

            #{$root}__External {
                display: block;
            }

            #{$root}--Mega #{$root}__List:not(#{$root}__List--Children) > #{$root}__Item > & {
                #{$root}__LinkText {
                    margin-right: 0;
                }

                #{$root}__External {
                    display: none;
                }
            }
        }

        &--Large {
            @include href-external {
                &::after {
                    background-image: url('#{$assetsPath}/img/external--white.svg') !important;
                }

                &:hover {
                    &::after {
                        background-image: url('#{$assetsPath}/img/external--white.svg') !important;
                    }
                }
            }
        }

        &:not(#{$root}__Link--Large) {
            @include href-external {
                &::after {
                    background-image: url('#{$assetsPath}/img/external--red.svg');
                }

                &:hover {
                    &::after {
                        background-image: url('#{$assetsPath}/img/external--white.svg');
                    }
                }
            }
        }
    }
}

@import 'Styles/includes';

.CopyToClipboard {
    $root: &;

    position: relative;

    &__Tooltip {
        position: absolute;
        bottom: calc(100% + 9px);
        left: 50%;
        margin: auto;
        padding: 5px 11px 6px;
        color: white;
        font-size: 1.2rem;
        font-weight: $regular;
        border-radius: 3px;
        background: $grey-90;
        transform: translateX(-50%);
        opacity: 1;

        #{$root}--Copied & {
            :global {
                animation: fadeOut 1s 4s forwards;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $grey-90 transparent transparent transparent;
        }
    }
}
